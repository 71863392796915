import { storyblokEditable } from '@storyblok/react/rsc'
import { clsx } from 'clsx'
import Image from 'next/image'
import { type FC } from 'react'

import tailwindPresetScreenSizes from 'tailwind-preset/screens.cjs'

import { type StoryblokComponentProps } from '../../model'
import { storyAccessorGetBorderRadius } from '../../model/storyAccessors/storyAccessorGetBorderRadius'
import { type StoryblokImageV2Type } from '../../model/storyblokTypes.generated'
import { useStoryblokStyles } from '../StoryblokStyles/useStoryblokStyles'

export const StoryblokImageV2: FC<
  StoryblokComponentProps<StoryblokImageV2Type>
> = ({ blok }) => {
  const {
    alt,
    borderRadius,
    fill,
    height,
    heightTablet,
    responsiveStyles,
    src,
    srcTablet,
    styles,
    stylesDesktop,
    stylesTablet,
    width,
    widthTablet,
  } = blok
  const { blokClasses } = useStoryblokStyles({
    responsiveStyles,
    styles,
    stylesDesktop,
    stylesTablet,
  })

  const borderClasses = borderRadius
    ? storyAccessorGetBorderRadius(borderRadius)
    : blokClasses

  if (!src) {
    return null
  }

  const imageProps = {
    ...(!fill && height ? { height: Number(height) } : {}),
    ...(!fill && width ? { width: Number(width) } : {}),
    ...(fill ? { fill: Boolean(fill) } : {}),
  }

  const tabletImageProps = {
    ...(!fill && height ? { height: Number(heightTablet) } : {}),
    ...(!fill && width ? { width: Number(widthTablet) } : {}),
    ...(fill ? { fill: Boolean(fill) } : {}),
  }

  return (
    <picture {...storyblokEditable(blok)}>
      {srcTablet ? (
        <source
          media={`(min-width: ${tailwindPresetScreenSizes.tablet})`}
          srcSet={srcTablet}
          {...tabletImageProps}
        />
      ) : null}

      <Image
        alt={alt}
        className={clsx(fill && 'object-contain', borderClasses)}
        src={src}
        {...imageProps}
        unoptimized
      />
    </picture>
  )
}
