import { clsx } from 'clsx'

export const storyAccessorGetBorderRadius = (
  rounded?: string | number,
): string => {
  return clsx(
    rounded === 'none' && 'rounded-none',
    rounded === 'small' && 'rounded-lg',
    rounded === 'medium' && 'rounded-2xl',
    rounded === 'large' && 'rounded-3xl',
    rounded === 'full' && 'rounded-full',
  )
}
